import {
  CardElement,
  useElements,
  useStripe,
  PaymentElement,
  LinkAuthenticationElement,
  AddressElement,
} from "@stripe/react-stripe-js";
// import { Button, Input } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Text, Group } from "@mantine/core";
import { useUserAuth } from "../contexts/UserAuthContext";
import ConfettiExplosion from "react-confetti-explosion";
import { update } from "lodash";

const standardPrice = 49.99;

function CheckoutForm({
  subId,
  data,
  props,
  promoCode,
  setPromoCode,
  isTrialedSubscription,
  stripeId,
}) {
  // Subscription fields
  const [name, setName] = useState(data.name);
  const [email, setEmail] = useState(data.email);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [promoErrorMessage, setPromoErrorMessage] = useState(null);
  const [promoSuccessMessage, setPromoSuccessMessage] = useState(null);
  const [isPromoLoading, setIsPromoLoading] = useState(false);
  const [updatedPrice, setUpdatedPrice] = useState(
    isTrialedSubscription ? 0.0 : standardPrice
  );
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    useState(null);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
  const promoCodeRef = useRef(null); // Create reference to the input field

  const stripe = useStripe();
  const elements = useElements();

  // Change input back to button upon click away
  useEffect(() => {
    // call function whenever you click on the document
    const handleClickOutside = (event) => {
      if (
        promoCodeRef.current &&
        !promoCodeRef.current.contains(event.target)
      ) {
        if (promoCode === "") {
          setShowPromoInput(false);
        }
      }
    };

    // Event listener
    document.addEventListener("mousedown", handleClickOutside);

    // remove listener is component unmounts

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [promoCode]);

  // Call validateCoupon cloud function to validate promo code.
  const validatePromoCode = async (promoCode) => {
    try {
      const response = await fetch(
        `https://us-central1-${process.env.REACT_APP_FIREBASE_ENVIRONMENT}.cloudfunctions.net/validateCoupon`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            promoName: promoCode,
            subId: subId,
            trialEnabled: isTrialedSubscription,
          }),
        }
      );

      const responseContent = await response.json();
      return responseContent;
    } catch (error) {
      console.error("Error validating coupon:", error);
    }
  };

  const handleApplyButtonClick = async () => {
    setIsPromoLoading(true);
    setPromoErrorMessage(null);
    setPromoSuccessMessage(null);
    const response = await validatePromoCode(promoCode);
    try {
      if (response.valid) {
        let discountAmount = 0;
        if (response.amount_off) {
          discountAmount = response.amount_off / 100;
        } else if (response.percent_off) {
          discountAmount = standardPrice * (response.percent_off / 100);
        }

        const newPrice = standardPrice - discountAmount;
        setUpdatedPrice(newPrice.toFixed(2)); // Method ensures always 2 decimal places

        let discountString = "";
        if (response.amount_off) {
          discountString = `$${response.amount_off / 100} off`; // if 'amount_off' is in cents
        } else if (response.percent_off) {
          discountString = `${response.percent_off}% off`;
        }

        let durationString = "";
        switch (response.duration) {
          case "forever":
            durationString = "for all future charges";
            break;
          case "once":
            durationString = "for the next charge";
            break;
          case "repeating":
            durationString = `for the next ${response.duration_in_months} charges`;
            break;
          default:
            durationString = "";
            break;
        }

        // Confetti explosion when the button is clicked
        setIsExploding(true);
        setTimeout(() => setIsExploding(false), 2000); // 2 seconds delay
        setPromoSuccessMessage(
          `Coupon Applied! ${discountString} ${durationString}. `
        );
        setIsPromoLoading(false);
      } else {
        setPromoErrorMessage("Invalid Coupon.");
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
      setPromoErrorMessage(
        "Error applying promo code. Please try again later."
      );
    } finally {
      setIsPromoLoading(false);
    }
  };

  // Confirms stripe Payment and fowards to success URL if successful
  const confirmSubscription = async () => {
    setIsSubscriptionLoading(true);
    setSubscriptionErrorMessage(null);
    try {
      stripe
        .confirmSetup({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/finalquestions`,
          },
          redirect: "if_required",
        })
        .then(async (result) => {
          if (result.error != null) {
            if (result.error.code == "card_declined") {
              setSubscriptionErrorMessage(
                "Card declined, please try again later or try a different payment method."
              );
            } else {
              setSubscriptionErrorMessage(
                "An error has occured while processing your payment information. Please try again."
              );
            }
            setIsSubscriptionLoading(false);
          } else if (result.setupIntent.status == "succeeded") {
            // Add Able CDP tracking call 
            window.uipe("track", "Purchase", {
              keys: { email: email },
              payment: {
                amount: updatedPrice, 
                currency: "USD", 
                transactionId: result.setupIntent.id, 
              },
            });
            try {
              const response = await fetch(
                `https://us-central1-${process.env.REACT_APP_FIREBASE_ENVIRONMENT}.cloudfunctions.net/createSubscription`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    customerId: stripeId,
                    promoName: promoCode,
                    trialEnabled: isTrialedSubscription,
                  }),
                }
              );
              if (response.ok) {
                window.location = `${window.location.origin}/finalquestions`;
              } else {
                setSubscriptionErrorMessage(
                  "An error has occured while confirming your subscription. Please try again later or contact support."
                );
                setIsSubscriptionLoading(false);
              }
            } catch (e) {
              setSubscriptionErrorMessage(
                "An error has occured while confirming your subscription. Please try again later or contact support."
              );
              setIsSubscriptionLoading(false);
            }
          } else {
            setSubscriptionErrorMessage(
              "An error has occured while confirming your subscription. Please try again later or contact support."
            );
            setIsSubscriptionLoading(false);
          }
        });
    } catch (e) {
      setSubscriptionErrorMessage(
        "An error has occured while confirming your subscription. Please try again later."
      );
      setIsSubscriptionLoading(false);
    }
  };

  return (
    <div className="max-w-[690px]">
      <div className="grid gap-1 m-auto mt-1">
        <div className=" justify-center grid gap-1 m-auto ">
          {isTrialedSubscription && (
            <Text
              fz="xl"
              fw={700}
              className="font-Barlow text-3xl font-bold text-bb-light-green"
            >
              7 days free
            </Text>
          )}
        </div>
        <br />
        <Group position="apart">
          <div>
            <Text fz="sm" fw={700}>
              Bid Banana Standard Plan
            </Text>
          </div>
          <div>
            <Text fz="sm" fw={700}>
              {isTrialedSubscription ? "7 days free" : "$49.99 per month"}
            </Text>
          </div>
        </Group>
        {isTrialedSubscription && (
          <Group position="apart">
            <div></div>
            <div>
              <Text fz="sm" c="dimmed">
                $49.99/month after
              </Text>
            </div>
          </Group>
        )}

        {/* Top section */}

        <label htmlFor="Name" className="font-sans text-sm">
          Name
        </label>
        <input
          placeholder="Name"
          type="text"
          disabled={true}
          value={name}
          readOnly
          className="p-2 rounded-md border-radius-borderRadius 
        transition duration-150 ease-in-out border-1
        shadow-sm focus:ring focus:border-blue-300 mb-2 font-sans text-sm"
        />
        <div className="mb-4">
          <LinkAuthenticationElement
            options={{
              defaultValues: { email: email },
            }}
          />
        </div>
        {/* <label for="Email">Email</label>
      <input placeholder="Email" type="text" value={email} readOnly /> */}
        <PaymentElement />
        <Button
          variant="outline"
          size="sm"
          type="submit"
          radius="xl"
          className="my-3 hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
          disabled={!stripe || isSubscriptionLoading}
          id="submit"
          loading={isSubscriptionLoading}
          loaderPosition="left"
          onClick={confirmSubscription}
        >
          <span id="button-text">{"Pay now"}</span>
        </Button>
        {subscriptionErrorMessage && (
          <p className="flex justify-center text-red-600">
            {subscriptionErrorMessage}
          </p>
        )}

        {/* Divider */}
        {/* <Divider label="Enter payment details" labelPosition="center" my="lg" /> */}
        {/* Divider */}

        {/* Bottom section */}
        <Divider labelPosition="center" my="sm" />
        {showPromoInput ? (
          <>
            <input
              ref={promoCodeRef} // adding reference to input field
              placeholder="Add promotion code"
              type="text"
              size="1"
              id="couponInput"
              value={promoCode}
              className="p-2 rounded-md border-radius-borderRadius 
              transition duration-150 ease-in-out border-1
              shadow-sm focus:ring focus:border-blue-300 my-2 font-sans text-sm "
              onChange={(event) => setPromoCode(event.target.value)}
            />
            {promoErrorMessage && (
              <p className="flex justify-center text-red-700">
                {promoErrorMessage}
              </p>
            )}
            {promoSuccessMessage && (
              <p className="flex justify-center text-green-700">
                {promoSuccessMessage}
              </p>
            )}
            <>
              <div className="flex justify-center">
                {isExploding && (
                  <ConfettiExplosion
                    colors={[
                      "#CDFFF0",
                      "#d5eeed",
                      "#87eeab",
                      "#5184B2",
                      "#FEE299",
                    ]}
                    duration={2800}
                    force={0.6}
                    height={"190vh"}
                  />
                )}
              </div>
              <Button
                id="Confetti"
                className="p-2 rounded-lg border-1 px-2 shadow-sm my-2 font-sans text-sm transition ease-in-out bg-bb-yellow  text-slate-600 hover:bg-bb-yellow w-auto duration-200"
                onClick={handleApplyButtonClick}
                loading={isPromoLoading}
                loaderPosition="left"
              >
                Apply
              </Button>
            </>
            {/* Render the ConfettiExplosion when isExploding is true */}
          </>
        ) : (
          <div className="flex">
            <Button
              onClick={() => setShowPromoInput(true)}
              className="p-2 rounded-lg border-1 px-2 shadow-sm my-2 font-sans text-sm transition ease-in-out bg-bb-yellow  text-slate-600 hover:border-1 hover:bg-bb-yellow w-auto duration-200"
            >
              Add promotion code
            </Button>
          </div>
        )}
        <Divider labelPosition="center" my="sm" />
        {isTrialedSubscription && (
          <Group position="apart">
            <div>
              <Text fz="sm" c="dimmed">
                Total due after 7 day trial
              </Text>
            </div>
            <div>
              <Text fz="sm" fw={700}>
                ${promoSuccessMessage ? updatedPrice : standardPrice.toFixed(2)}
              </Text>
            </div>
          </Group>
        )}

        <Group position="apart">
          <div>
            <Text fz="sm" fw={700}>
              Total due today
            </Text>
          </div>
          <div>
            {/* Make this dynamic */}
            <Text fz="sm" fw={700}>
              {"$" + updatedPrice}
            </Text>
          </div>
        </Group>
      </div>
    </div>
  );
}

export default CheckoutForm;
