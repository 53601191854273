import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ActionIcon } from "@mantine/core";
import { BsTrash3 } from "react-icons/bs";
import useBidFinderClients from "../hooks/useBidFinderClients";
import { DeleteConfirmationModal, BidCardSkeleton } from ".";
import { HiOutlineExternalLink } from "react-icons/hi";
import { InputTextarea } from "primereact/inputtextarea";
import { showNotification } from "@mantine/notifications";
import { Switch } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";

const BidFinderClientsTable = ({ refresh }) => {
	// Using the custom hook to get clients and the delete function
	const { clients, loading, deleteClient, deleteBid, toggleSendEmails, updateClientData, updateBidData } = useBidFinderClients(refresh);

	// State to control the visibility of the delete confirmation modal
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
	const [expandedRows, setExpandedRows] = useState({});

	// State to keep track of the entity to delete ('user' or 'bid') and associated IDs
	// The deletionContext state variable essentially acts as a representation of the current context:
	// whether the action is about to delete a user or a bid and the respective IDs.
	const [deletionContext, setDeletionContext] = useState(null); // null or {entityType: 'user' or 'bid', userId: '...', bidId: '...'}

	// Function to trigger the delete confirmation modal. Stores the entitytype and IDs.
	const showDeleteModal = (entityType, userId, bidId = null) => {
		setDeletionContext({ entityType, userId, bidId });
		setIsDeleteModalVisible(true);
	};

	// Handles the confirmation of deletion. Based on the context, either a user or bid is deleted.
	const handleConfirmDelete = () => {
		if (deletionContext.entityType === "user") {
			deleteClient(deletionContext.userId);
			showNotification({
				title: "Success!",
				message: `Client ${deletionContext.userId} deleted successfully!`,
				color: "green",
			});
		} else if (deletionContext.entityType === "bid") {
			deleteBid(deletionContext.userId, deletionContext.bidId);
			showNotification({
				title: "Success!",
				message: `Bid deleted successfully!`,
				color: "green",
			});
		}
		setIsDeleteModalVisible(false);
		setDeletionContext(null); // Reset the context after deletion
	};

	// Renderer for the clients Bid Finder URL
	const BidFinderURLRenderer = (rowData) => {
		return (
			<div>
				<a href={`/bidfinder/${rowData.id}`} target="_blank" rel="noreferrer" className="hover:cursor-pointer">
					<HiOutlineExternalLink className="text-large text-gray-600" />
				</a>
			</div>
		);
	};

	// Renderer for the clients Bid Finder URL
	const BidURLRenderer = (rowData) => {
		return (
			<div>
				<a href={rowData.url} target="_blank" rel="noreferrer" className="hover:cursor-pointer">
					<HiOutlineExternalLink className="text-large text-gray-600" />
				</a>
			</div>
		);
	};

	// Renderer for the delete button for clients
	const deleteButtonRendererUser = (rowData) => {
		return (
			<ActionIcon onClick={() => showDeleteModal("user", rowData.id)}>
				<BsTrash3 size={18} />
			</ActionIcon>
		);
	};

	// Renderer for the delete button for bids
	const deleteButtonRendererBids = (bidRowData, parentRowData) => {
		return (
			<div>
				<ActionIcon onClick={() => showDeleteModal("bid", parentRowData.id, bidRowData.id)}>
					<BsTrash3 size={18} />
				</ActionIcon>
			</div>
		);
	};

	// Renderer for the send email toggle
	const sendEmailToggle = (rowData) => {
		const handleChange = async (checked) => {
			try {
				await toggleSendEmails(rowData.id, checked);

				// Show success notification
				showNotification({
					title: "Success",
					message: `Email notifications ${checked ? "enabled" : "disabled"} for user.`,
					color: "green",
				});
			} catch (error) {
				console.error("Error updating send_emails:", error);
				// Show error notification
				showNotification({
					title: "Error",
					message: "Failed to update email notification settings.",
					color: "red",
				});
			}
		};

		return (
			<div>
				<Switch
					checked={rowData.send_emails}
					onChange={(event) => handleChange(event.currentTarget.checked)}
					onLabel="ON"
					offLabel="OFF"
					size="md"
					color="teal"
					thumbIcon={
						rowData.send_emails ? (
							<IconCheck size={12} color="teal" stroke={3} />
						) : (
							<IconX size={12} style={{ color: "#f97657" }} stroke={3} />
						)
					}
				/>
			</div>
		);
	};

	// Row expansion template to show additional details (saved bids) for a user
	const rowExpansionTemplate = (parentRowData) => {
		return (
			<div className="overflow-auto">
				<DataTable value={parentRowData.saved_bids} removableSort className="font-Barlow" size="small" scrollable stripedRows>
					<Column
						field="title"
						header="Title"
						sortable
						style={{ width: "15%" }}
						editor={(options) => cellEditor(options)}
						onCellEditComplete={(e) => {
							onCellEditComplete(e, parentRowData);
						}}
					></Column>
					<Column
						field="agency"
						header="Agency"
						sortable
						style={{ width: "10%" }}
						editor={(options) => cellEditor(options)}
						onCellEditComplete={(e) => {
							onCellEditComplete(e, parentRowData);
						}}
					></Column>
					<Column
						field="open_date"
						header="Open Date"
						sortable
						style={{ width: "5%" }}
						sortField="open_timestamp"
						editor={(options) => cellEditor(options)}
						onCellEditComplete={(e) => {
							onCellEditComplete(e, parentRowData);
						}}
					></Column>
					<Column
						field="close_date"
						header="Close Date"
						sortable
						sortField="close_timestamp"
						style={{ width: "5%" }}
						editor={(options) => cellEditor(options)}
						onCellEditComplete={(e) => {
							onCellEditComplete(e, parentRowData);
						}}
					></Column>
					<Column
						field="description"
						header="Description"
						sortable
						style={{ width: "55%" }}
						editor={(options) => cellEditor(options)}
						onCellEditComplete={(e) => {
							onCellEditComplete(e, parentRowData);
						}}
					></Column>
					<Column body={BidURLRenderer} header="URL" style={{ width: "5%" }}></Column>
					<Column body={(rowData) => deleteButtonRendererBids(rowData, parentRowData)} header="Delete Bid"></Column>
				</DataTable>
			</div>
		);
	};

	const onRowToggle = (e) => {
		setExpandedRows(e.data);
	};

	// Handle cell edit completion for the DataTable
	const onCellEditComplete = (e, parentRowData = null) => {
		const { rowData, newValue, field } = e;

		// If the cell value is empty after editing, notify the user and exit the function
		if (newValue.trim().length === 0) {
			showNotification({
				title: "Error",
				message: "Value cannot be empty",
				color: "red",
			});
			return;
		}

		// If the editing comes from the inner (nested) DataTable (i.e., bids table)
		if (parentRowData) {
			const clientId = parentRowData.id;
			const bidId = rowData.id;

			// Use computed property names to construct the update object based on the edited field
			const updateData = { [field]: newValue };

			// Call the function to update bid data
			updateBidData(clientId, bidId, updateData);

			console.log(`Bid [${bidId}] for Client [${clientId}] updated. Field: ${field}, New Value: ${newValue}`);
		} else {
			// If the editing comes from the main DataTable (i.e., clients table)
			const clientId = rowData.id;
			const updateData = { [field]: newValue };

			// Call the function to update user data
			updateClientData(clientId, updateData);

			console.log(`Client [${clientId}] updated. Field: ${field}, New Value: ${newValue}`);
		}
	};

	const cellEditor = (options) => {
		return (
			<div className="grid">
				<InputTextarea
					type="text"
					autoResize
					value={options.value}
					onChange={(e) => {
						options.editorCallback(e.target.value);
					}}
				/>
			</div>
		);
	};

	if (loading) {
		return <BidCardSkeleton />;
	}

	return (
		<div className="overflow-auto">
			<DataTable
				value={clients}
				removableSort
				rowExpansionTemplate={rowExpansionTemplate}
				expandedRows={expandedRows}
				onRowToggle={onRowToggle}
				dataKey="id"
				className="font-Barlow "
				editMode="cell"
				size="small"
				scrollable
			>
				<Column expander style={{ width: "3em" }} />
				<Column
					field="organization_name"
					header="Organization Name"
					sortable
					style={{ width: "20%" }}
					editor={(options) => cellEditor(options)}
					onCellEditComplete={onCellEditComplete}
				></Column>
				<Column
					field="email_notification_day"
					header="Notification Day"
					sortable
					style={{ width: "15%" }}
					editor={(options) => cellEditor(options)}
					onCellEditComplete={onCellEditComplete}
				></Column>
				<Column
					field="emails"
					header="Emails"
					sortable
					style={{ width: "40%" }}
					editor={(options) => cellEditor(options)}
					onCellEditComplete={onCellEditComplete}
				></Column>
				<Column
					body={(rowData) => sendEmailToggle(rowData)}
					style={{ width: "15%" }}
					// field="send_emails"
					header="Send Email"
					className="min-w-[1em]"
				></Column>
				<Column
					body={BidFinderURLRenderer}
					style={{ width: "10%" }}
					header="URL"
					//className="min-w-[1em] flex justify-center"
				></Column>
				<Column
					body={deleteButtonRendererUser}
					style={{ width: "5%" }}
					header="Delete Client"
					className="min-w-[6em] flex justify-center"
				></Column>

				{/* Add more columns as needed */}
			</DataTable>
			<DeleteConfirmationModal
				isOpen={isDeleteModalVisible}
				onClose={() => {
					setIsDeleteModalVisible(false);
					setDeletionContext(null); // Resetting the deletion context when closing the modal
				}}
				onDelete={handleConfirmDelete}
				entityType={deletionContext?.entityType} // Using optional chaining in case deletionContext is null
			/>
		</div>
	);
};

export default BidFinderClientsTable;
