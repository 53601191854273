import { useState, useEffect } from "react";
import { getFirestore, doc, deleteDoc, collection, getDocs, updateDoc } from "firebase/firestore";
import { showNotification } from "@mantine/notifications";

const useBidFinderClients = (refresh) => {

	const [clients, setClients] = useState([]);
	const [loading, setLoading] = useState(true);
	const firestore = getFirestore();

	// Fetch all Bid Finder Clients

	// fetchClients() fetches the client from the bidfinder_clients collection in Firestore
	const fetchClients = async (overrideLoading = false) => {

		// Check if the loading state should be ommitted from this function call.
		if (!overrideLoading) {
			setLoading(true);
		}

		try {
			// Create a reference to the bidfinder_users collection
			const clientCollection = collection(firestore, "bidfinder_clients");

			// Get all documents from the collection
			const clientSnapshots = await getDocs(clientCollection);

			// 2. For each user, prepare a promise to fetch their saved bids from the "saved_bids" sub-collection.
			const bidsPromises = clientSnapshots.docs.map(async (clientDoc) => {
				const bidsSnapshot = await getDocs(collection(clientDoc.ref, "saved_bids"));
				const bids = bidsSnapshot.docs.map((bidDoc) => ({
					...bidDoc.data(),
					id: bidDoc.id,
				}));

				// Return combined user data along with their respective saved bids.
				return {
					...clientDoc.data(),
					id: clientDoc.id,
					saved_bids: bids,
				};
			});

			// 3. Resolve all promises concurrently using Promise.all.
			const allClientData = await Promise.all(bidsPromises);

			// Set the fetched users to the local state
			setClients(allClientData);
			
		} catch (error) {
			console.error("Error fetching bidfinder_clients:", error);
		}
		setLoading(false);
	};

	// Delete Bid Finder User
	const deleteClient = async (clientId) => {
		try {
			// Reference to the user's document
			const clientDocRef = doc(firestore, "bidfinder_clients", clientId);

			// 1. Delete all documents within the `saved_bids` subcollection.
			const bidsCollectionRef = collection(clientDocRef, "saved_bids");
			const bidsSnapShot = await getDocs(bidsCollectionRef);

			// Delete each bid document from the saved_bids sub-collection
			const deletePromises = bidsSnapShot.docs.map((bidDoc) => deleteDoc(bidDoc.ref));
			await Promise.all(deletePromises);

			// Delete the main user document after all bids are deleted
			await deleteDoc(clientDocRef);

			// Immediately update the local state to reflect the deletion
			setClients((prevClients) => prevClients.filter((client) => client.id !== clientId));

			// Fetch the updated list of users after deletion
			fetchClients();
		} catch (error) {
			console.error("Error removing client from bidfinder_clients:", error);
		}
	};

	const deleteBid = async (clientId, bidId) => {
		try {
			// Reference to the bid's document within the user's subcollection
			const bidDocRef = doc(firestore, "bidfinder_clients", clientId, "saved_bids", bidId);

			// Delete the bid
			await deleteDoc(bidDocRef);

			// Immediately update the local state to reflect the deleteion
			setClients((prevClients) => {
				return prevClients.map((client) => {
					if (client.id === clientId) {
						return {
							...client,
							saved_bids: client.saved_bids.filter((bid) => bid.id !== bidId),
						};
					}
					return client;
				});
			});

			fetchClients();
		} catch (error) {
			console.error("Error removing bid:", error);
		}
	};

	const updateClientData = async (clientId, newClientData) => {

		console.log("Updating user data with clientId:", clientId);

		try {
			const clientDocRef = doc(firestore, "bidfinder_clients", clientId);

			// Use the updateDoc function to update the users data
			await updateDoc(clientDocRef, newClientData);

			// Fetch the updated list of users after updating
			fetchClients(true);

		} catch (error) {
			console.log("Error updating user data:", error);
		}
	};

	const updateBidData = async (clientId, bidId, updateBidData) => {
		console.log("Updating bid data with userId:", clientId, "and bidId:", bidId);
		try {

			const bidDocRef = doc(firestore, "bidfinder_clients", clientId, "saved_bids", bidId);

			// Use the updateDoc function to update the bid data
			await updateDoc(bidDocRef, updateBidData);
			
			// Fetch the updated list of users after updating the bid
			console.log("Bid data successfully updated!");

			fetchClients(true);
		} catch (error) {
			console.log("Error updating bid data:", error);
		}
	};

	const toggleSendEmails = async (clientId, shouldSendEmails) => {
		try {
			// Update local state optimistically
			setClients((currentClients) =>
				currentClients.map((client) => (client.id === clientId ? { ...client, send_emails: shouldSendEmails } : client))
			);

			const clientDocRef = doc(firestore, "bidfinder_clients", clientId);

			// Update Firestore
			await updateDoc(clientDocRef, {
				send_emails: shouldSendEmails,
			});

		} catch (error) {

			console.error("Error updating send_emails:", error);

			// Revert local state change in case of error
			setClients((currentClients) => currentClients.map((client) => (client.id === clientId ? { ...client, send_emails: !shouldSendEmails } : client)));

			// Show error notification
			showNotification({
				title: "Error",
				message: "Failed to update email notification setting",
				color: "red",
			});

		}
	};

	// Get current list of Bid Finder ..Refresh
	useEffect(() => {
		// Call the fetchUsers function to get the current list of users
		fetchClients();
	}, [firestore, refresh]);

	return {
		clients,
		loading,
		deleteClient,
		deleteBid,
		updateClientData,
		updateBidData,
		toggleSendEmails,
	};
};

export default useBidFinderClients;
