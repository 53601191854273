import React, { useEffect, useState } from "react";
import {
  Loader,
  Button,
  Group,
  Text,
  TextInput,
  Modal,
  Card,
  PasswordInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useUserAuth } from "../contexts/UserAuthContext";
import { BananaBanner, PleaseLogin } from "../components";
import { AiOutlineCheck } from "react-icons/ai";
import { showNotification, updateNotification } from "@mantine/notifications";
import { Link, NavLink } from "react-router-dom";
import { FaUserCheck } from "react-icons/fa";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const Settings = () => {
  useAbleCDPTracking();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  function openReAuthModal() {
    const newEmail = emailForm.values.email;
    setPasswordError(false);

    // If the email hasn't changed, simply update the other user data without re-authenticating
    if (user.email === newEmail) {
      submitDataForm(
        dataForm.values.name,
        dataForm.values.organizationName,
        dataForm.values.phoneNumber,
        newEmail
      );
      return;
    }

    // If email is different, proceed to open the modal for re-authentication
    setModalOpen(true);
  }

  function closeReAuthModal() {
    setModalOpen(false);
    setUserPassword(""); // clears password input when closing modal
  }

  const {
    user,
    updateUserAccountData,
    data,
    resetUserPassword,
    handleUpdateEmail,
  } = useUserAuth();

  const dataForm = useForm({
    initialValues: {
      name: data != null ? data.name : "loading...",
      organizationName: data != null ? data.organization_name : "loading...",
      phoneNumber: data != null ? data.phone_number : "loading...",
    },
  });

  const emailForm = useForm({
    initialValues: {
      email: user != null ? user.email : "loading...",
    },
  });

  // Populates the forms after data has been loaded
  useEffect(() => {
    if (user && data) {
      dataForm.setValues({
        name: data.name,
        organizationName: data.organization_name,
        phoneNumber: data.phone_number,
      });
      emailForm.setValues({ email: user.email });
    } else if (user) {
      dataForm.setValues({ name: "", organizationName: "", phoneNumber: "" });
      emailForm.setValues({ email: user.email });
    }
  }, [user, data]);

  // Render loader if loading
  if (loading || data === null || user === null) {
    return (
      <div
        id="wrapper"
        className="h-fit flex-grow flex-col w-full relative justify-center"
      >
        <Loader
          variant="dots"
          color="bb-light-green.0"
          size="xl"
          className="flex m-auto items-center justify-center h-screen"
        />
      </div>
    );
  }

  const titleText =
    user && data && data.name
      ? "Hi, " + data.name + "!"
      : "Welcome to Bid Banana";

  let userImg;

  if (user) {
    if (user.photoURL != null) {
      userImg = (
        <img
          className="h-12 rounded-full cursor-pointer inline-flex align-baseline"
          src={user.photoURL}
          height={48}
          width={48}
          alt="User Account"
          referrerPolicy="no-referrer"
        />
      );
    } else {
      userImg = <FaUserCheck size={22} />;
    }
  }

  const submitDataForm = async (
    name,
    organizationName,
    phoneNumber,
    newEmail
  ) => {
    console.log("Submitting data form...");

    showNotification({
      id: "load-data",
      title: "Updating user data...",
      color: "yellow",
      loading: true,
    });

    try {
      console.log("Updating user account data...");
      await updateUserAccountData(name, organizationName, phoneNumber);

      console.log("User data updated!");
      updateNotification({
        id: "load-data",
        title: "User data updated!",
        color: "green",
        icon: <AiOutlineCheck size={16} />,
        autoClose: 2000,
      });

      // Check if the email has changed
      if (user.email !== newEmail) {
        try {
          console.log("Updating email...");
          await handleUpdateEmail(newEmail, userPassword);
          console.log("Email updated successfully!");
          setPasswordError(false); // Reset the password error status on success
        } catch (error) {
          console.error("Error updating email:", error);
          setPasswordError(true);
        }
      }
    } catch (error) {
      console.error("Error updating user data:", error);

      // Handle the error and show a different notification if necessary
      updateNotification({
        id: "load-data",
        title: "Error updating user data",
        color: "red",
        autoClose: 2000,
      });
    }
  };

  if (!user) {
    return (
      <div
        id="Favorites"
        className="mb-10 h-fit flex-grow flex-col w-full relative justify-center"
      >
        <div
          id="wrapper"
          className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
        >
          <BananaBanner title1="Oops!" title2="Please login" />
          <section>
            <PleaseLogin
              text="Settings page is only available to Bid Banana Users"
              description="Please login to view settings or create an account!"
            />
          </section>
          <div
            id="buttons wrapper"
            className="flex text-center justify-center sm:mt-12 sm:mb-5 "
          >
            <Button
              variant="outline"
              type="type"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              component={Link}
              to="/register"
            >
              Sign up to continue
            </Button>
            <div className="inline-block align-baseline pt-2">
              <Link
                to="/login"
                className="text-bb-light-green font-semibold mx-8 align-baseline"
              >
                Or sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <BananaBanner/> */}
      <div
        id="wrapper"
        className="h-fit flex-grow w-full relative justify-center"
      >
        <div id="inner-wrapper" className="sm:px-8 sm:pt-8 sm:pb-10">
          <section id="content" role="main">
            <div
              id="containedData"
              className=" py-4 sm:max-w-4xl sm:py-8 overflow-auto relative sm:m-auto"
            >
              {/* Header Bar  */}
              <div className="block w-full  my-4 sm:mb-8 pl-7 sm:pl-0">
                <Group>
                  <div id="photoURL">{userImg}</div>
                  <div style={{ flex: 1 }}>
                    <Text
                      size="sm"
                      weight={500}
                      className="text-xl text-black font-semibold"
                    >
                      {titleText}
                    </Text>
                    <Text color="dimmed" size="md">
                      Update your account profile details
                    </Text>
                  </div>
                </Group>
              </div>
              {/* Mobile menu */}
              <div className="flex justify-center sm:hidden"></div>
              {/* Side bar  */}
              <div
                id="sideBar"
                className=" pl-2 sm:block sm:text-lg sm:float-left sm:w-23/100"
              >
                <ul className="mb-5">
                  {/* Side Links */}

                  <NavLink to="/settings">
                    <li className="pl-1 text-lg font-medium">
                      <strong>My Profile</strong>
                    </li>
                  </NavLink>

                  <NavLink to="/savedsearches">
                    <li className="pl-1 text-lg font-medium">Saved Searches</li>
                  </NavLink>

                  <NavLink to="/billing">
                    <li className="pl-1 text-lg font-medium">Billing</li>
                  </NavLink>
                </ul>
              </div>
              {/* Main Content */}

              <div id="main container" className="px-2 sm:float-right sm:w-4/5">
                <Card withBorder radius="md" className="sm:w-4/5 my-2 py-6">
                  <form
                    onSubmit={dataForm.onSubmit((values) =>
                      submitDataForm(
                        values.name,
                        values.organizationName,
                        values.phoneNumber,
                        emailForm.values.email
                      )
                    )}
                  >
                    <TextInput
                      mb="md"
                      size="md"
                      label="Name"
                      required
                      placeholder="Name"
                      styles={{
                        label: {
                          fontWeight: "600",
                        },
                      }}
                      {...dataForm.getInputProps("name")}
                    />
                    <TextInput
                      mt="md"
                      size="md"
                      mb="md"
                      label="Email"
                      required
                      placeholder="Email"
                      styles={{
                        label: {
                          fontWeight: "600",
                        },
                      }}
                      {...emailForm.getInputProps("email")}
                    />
                    <TextInput
                      mt="md"
                      size="md"
                      mb="md"
                      required
                      label="Organization Name"
                      placeholder="Organization Name"
                      styles={{
                        label: {
                          fontWeight: "600",
                        },
                      }}
                      {...dataForm.getInputProps("organizationName")}
                    />
                    <TextInput
                      mt="md"
                      size="md"
                      mb="md"
                      required
                      label="Phone Number"
                      placeholder="Phone Number"
                      styles={{
                        label: {
                          fontWeight: "600",
                        },
                      }}
                      {...dataForm.getInputProps("phoneNumber")}
                    />
                    <div className="flex justify-end my-5">
                      <Button
                        variant="outline"
                        onClick={() => {
                          resetUserPassword(user.email);
                        }}
                        type="type"
                        size="md"
                        radius="xl"
                        className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-bb-yellow border-1 border-black hover:bg-bb-yellow hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
                      >
                        Reset Password
                      </Button>
                      <Button
                        variant="outline"
                        type="button"
                        size="md"
                        radius="xl"
                        onClick={openReAuthModal} // Open the re-authentication modal when trying to save
                        className="hover:shadow-[0_5px_0px_rgba(0,0,0)] ml-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </Card>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Modal opened={modalOpen} onClose={closeReAuthModal} centered>
        <Text size="lg" style={{ marginBottom: "15px" }}>
          Please enter your password to update email
        </Text>
        <PasswordInput
          error={passwordError ? "Incorrect password" : null}
          value={userPassword}
          onChange={(e) => setUserPassword(e.target.value)}
          placeholder="Enter your password"
        />
        <div className="flex justify-end mt-4">
          <Button
            onClick={closeReAuthModal}
            className="text-gray-600 text-sm font-medium hover:text-gray-600 no-underline hover:no-underline hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 "
            variant="default"
            size="md"
            radius="xl"
          >
            Cancel
          </Button>
          <Button
            variant="outline"
            type="submit"
            size="md"
            radius="xl"
            style={{ marginLeft: "10px" }}
            onClick={async () => {
              const result = await handleUpdateEmail(
                emailForm.values.email,
                userPassword
              );

              if (result.success) {
                closeReAuthModal(); // Close the modal only if the operation was successful
              } else {
                console.error("Error updating email:", result.message);
                setPasswordError(true);
                // Optionally, you could also set an error state here to display a message in the modal.
              }
            }}
            className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green  border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Settings;
