import React, { useEffect, useState } from "react";
import {
  Paper,
  createStyles,
  Loader,
  TextInput,
  PasswordInput,
  Button,
  Title,
  Divider,
  Text,
  Center,
  Box,
  Progress,
  Group,
  List,
  Skeleton,
  BackgroundImage,
} from "@mantine/core";
import { CTAButton, MicrosoftButton } from "../components/index.js";
import { useInputState, useDisclosure } from "@mantine/hooks";
import { IconCheck, IconCircleCheck, IconX } from "@tabler/icons";
import { useUserAuth } from "../contexts/UserAuthContext.js";
import GoogleButton from "../components/SocialButtons/GoogleButton";

import { useForm } from "@mantine/form";
import background from "../data/collage.png";
import checkout from "../stripe/functions/Checkout";
import { Link, useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 1000,
    backgroundSize: "cover",
    backgroundImage: `url(${background})`,
  },

  form: {
    borderRight: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
    minHeight: 1000,
    maxWidth: 690,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },

    // padding set to 15 on mobile devices
    [theme.fn.smallerThan("sm")]: {
      padding: 15,
    },
  },
}));

// Display password strength elements to UI
function PasswordRequirement({ meets, label }) {
  return (
    <Text color={meets ? "green" : "red"} mt={5} size="sm">
      <Center inline>
        {meets ? (
          <IconCheck size={14} stroke={1.5} />
        ) : (
          <IconX size={14} stroke={1.5} />
        )}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

// Password criteria
const requirements = [
  { re: /[0-9]/, label: "includes number" },
  { re: /[a-z]/, label: "includes lowercase letter" },
  { re: /[A-Z]/, label: "includes uppercase letter" },
  { re: /[-+_!@#$%^&*.,?]/, label: "includes special symbol" },
];

function getStrength(password) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

// Main Register Page UI
const Register = () => {
  // Use loading when calling asynchronous functions
  const [loading, setLoading] = useState(false);

  // Toggle synced password visibility
  const [visible, { toggle }] = useDisclosure(false);

  const AuthContext = useUserAuth();

  const navigate = useNavigate();

  const [isAbleCDPLoaded, setIsAbleCDPLoaded] = useState(false);

  useEffect(() => {
    // Function to dynamically load the Able CDP script
    const loadAbleCDP = () => {
      const script = document.createElement('script');
      script.src = "https://app.ablecdp.com/ue.js";
      script.onload = () => {
        setIsAbleCDPLoaded(true);
        // console.log("Able CDP script loaded");
      };
      script.onerror = () => console.log("Error loading Able CDP script");
      document.head.appendChild(script);
    };

    if (!window.uipe) {
      loadAbleCDP();
    } else {
      // If window.uipe is already defined, we assume the script is loaded
      setIsAbleCDPLoaded(true);
    }
  }, []);

  useEffect(() => {
    // Once the script is loaded, initialize and track page view
    if (isAbleCDPLoaded) {
      window.uipe('init', '652a4334-c77f-4593-9dcc-c4e9fd85b7bb'); 
      window.uipe('track', 'PageView');
    }
  }, [isAbleCDPLoaded]);


  const { user, signUp } = useUserAuth();

  // state for managing password strength
  const [value, setValue] = useInputState("");

  const strength = getStrength(value);

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));

  // progress bars for password strength
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ bar: { transitionDuration: "0ms" } }}
        value={
          value.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
              ? 100
              : 0
        }
        color={strength > 80 ? "green" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  // Email validation logic
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid Email"),
      password: () => (strength > 80 ? null : "Invalid Password"),
      confirmPassword: (value, values) =>
        value !== values.password ? "Passwords must match" : null,
    },
  });

  // styles
  const { classes } = useStyles();

  // Render loader while new user is being created
  if (loading) {
    return (
      <div
        id="wrapper"
        className="h-fit flex-grow flex-col w-full relative justify-center"
      >
        <Loader
          variant="dots"
          color="bb-light-green.0"
          size="xl"
          className="flex m-auto items-center justify-center h-screen"
        />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <form
        onSubmit={form.onSubmit(async (values) => {
        // Check if the Able CDP script is loaded before tracking

          if (isAbleCDPLoaded) {
          window.uipe('track', 'Lead', {
            keys: { email: values.email,  }, 
            lead: { fullName: values.name, } 
          })
          console.log('Able CDP tracking data sent:', values);
        }

          signUp(values.email, values.password, values.name).then(async (resultUser) => {
            setLoading(true);
            navigate("/payments"); // Navigate to the payments page
          }).catch((e) => {
            if (e.code === "auth/email-already-in-use") {
              form.setErrors({ email: "Email already registered" });
            } else {
              form.setErrors({
                email: "An error has occurred, please try again",
              });
            }
            console.log(e);
          });
        })}
      >
        <Paper className={classes.form} radius={0} px={100} py={50}>
          {/* Title Area */}


          <div className="flex items-center sm:block my-4"></div>
          <Text
            order={2}
            className="font-Barlow text-3xl text-slate-700"
            align="center"
            fw={700}
            mt="md"
            size="xl"
            mb={10}
          >
            Sign up for Bid Banana
          </Text>

          <Text
            order={2}
            className="font-Barlow text-2xl text-slate-700"
            align="center"
            mt="md"
            fw={300}

            mb={40}
          >
            Free 7-day trial
          </Text>


          {/* Buttons */}
          <div id="buttonsContainer">
            <div className="sm:mb-3 w-full flex-nowrap inline-flex justify-center">
              {/*Social Buttons*/}
              <Button.Group>
                <GoogleButton text={"Continue with Google"} />
                <MicrosoftButton text={"Continue with Microsoft"} />
              </Button.Group>
            </div>
          </div>

          <Divider label="Or register with" labelPosition="center" my="lg" />

          <TextInput
            label="Name"
            placeholder="Banana Joe"
            size="md"
            mb="md"
            required
            {...form.getInputProps("name")}
          />

          <TextInput
            label="Email address"
            placeholder="hello@gmail.com"
            size="md"
            error={form.errors.email && "Invalid email"}
            required
            {...form.getInputProps("email")}
          />

          <PasswordInput
            value={value}
            onChange={(event) => {
              form.setFieldValue("password", event.currentTarget.value);
              setValue(event.currentTarget.value);
            }}
            label="Password"
            placeholder="Your password"
            mt="md"
            size="md"
            error={form.errors.password && "Invalid Password"}
            required
            className="peer"
            visible={visible}
            onVisibilityChange={toggle}
          />

          {/* Indicator bars  */}
          <div className="hidden peer-focus-within:block">
            <Group spacing={5} grow mt="xs" mb="mb">
              {bars}
            </Group>
            <PasswordRequirement
              label="Has at least 6 characters"
              meets={value.length > 5}
              className="invisible "
            />
            {checks}
          </div>

          <PasswordInput
            label="Confirm password"
            placeholder="Confirm"
            mt="md"
            required
            error={form.errors.password && "Invalid Password"}
            size="md"
            visible={visible}
            onVisibilityChange={toggle}
            {...form.getInputProps("confirmPassword")}
          />

          {/* back to login */}
          <Group position="apart">
            <div className=" justify-end py-4 flex  sm:pt-6 sm:flex sm:justify-start">
              <Text>
                Already have an account?
                <br />
                <Link
                  to="/login"
                  className="font-semibold text-base text-bb-light-green pb-2"
                >
                  Log in here
                </Link>
              </Text>
            </div>

            {/* CTA Button */}
            <div className=" justify-end py-4 flex  sm:py-6 sm:flex sm:justify-end">
              <Button
                variant="outline"
                size="sm"
                type="submit"
                radius="xl"
                className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
              >
                Sign Up
              </Button>
            </div>
          </Group>

          {/* Terms and Conditions */}
          <Text mt="md" size="md" align="justify">
            By signing in, you agree to Bid Banana's
            <Link
              to="/termsofuse"
              className="text-bb-light-green font-semibold align-baseline"
            >
              &nbsp;Terms of Use
            </Link>
            , and have read Bid Banana's
            <Link
              to="/privacypolicy"
              className="text-bb-light-green font-semibold align-baseline"
            >
              &nbsp;Privacy Policy
            </Link>
            .
          </Text>
        </Paper>
      </form>
    </div>
  );
};

export default Register;
