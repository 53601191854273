import React from 'react'
import { Image } from '@mantine/core'
import BananaBunch from '../data/Bid Banana BUNCH Banana Full Color.png'

export const EmptyQuery = () => {

  return (
    <div className='m-4 flex flex-col justify-center sm:flex items-center  sm:flex-col sm:mr-23 sm:ml-7 sm:min-w-4xl'>
      <div className=' p-0 sm:justify-center items-center  rounded-lg sm:pl-10 sm:pr-10 sm:ml-65 sm:mr-60'>
        <Image
          src={BananaBunch}
          alt="No way!"
          height={200}
          fit='scale-down'
          className='my-1 p-2 '
        />
        <p className='text-2xl font-semibold mb-0 text-center text-slate-800'>
          Enter a query of at least 3 characters in the box above to see search results!
        </p>
      </div>
    </div>
  )
}
