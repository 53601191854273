import React, { useEffect } from "react";
import { Button, Text, Card, Modal, TextInput } from "@mantine/core";
import { BsTrash3 } from "react-icons/bs";
import { MdOutlineEdit } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch";
import { useUserAuth } from "../contexts/UserAuthContext";
import { useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { Panel } from "./Panel";
import { useInstantSearch } from "react-instantsearch-hooks-web";

const SavedSearchesCardMinimal = ({ search, index, route }) => {
  const bids_index = process.env.REACT_APP_PRIMARY_BIDS_INDEX;
  const navigate = useNavigate();
  const AuthContext = useUserAuth();
  const user = AuthContext.user;
  const cardRoute = search.route;

  const { setUiState } = useInstantSearch();

  const runSearchOnClick = () => {
    try {
      const { query, facetFilters } = JSON.parse(search.query);

      // Check if query exists
      if (query) {
        let refinementList = {};
        let toggle = {};

        // Check if facetFilters exist and have at least one filter
        if (facetFilters && facetFilters.length > 0) {
          // Iterate over each facet filter
          facetFilters.forEach((filter) => {
            // Extract the filter key
            const key = filter[0].split(":")[0];

            // Check if the key is "login_required"
            if (key === "login_required") {
              // Extract the value for login_required
              const value = filter[0].split(":")[1] === "true";

              // Store the value in the toggle object
              toggle = {
                ...toggle,
                [key]: value,
              };
            } else {
              // Extract the filter values
              const values = filter.map((value) => value.split(":")[1]);

              // Store the values in the corresponding refinementList key
              refinementList[key] = values;
            }
          });
        }

        // Create the final search state object
        const searchState = {
          query,
          ...(Object.keys(refinementList).length > 0 && { refinementList }),
          ...(Object.keys(toggle).length > 0 && { toggle }),
        };

        if (bids_index == "bids") {
          // Update the UI state with the new search state
          setUiState((prevUiState) => ({
            ...prevUiState,
            bids: searchState,
          }));
        } else {
          setUiState((prevUiState) => ({
            ...prevUiState,
            bids_dev: searchState,
          }));
        }

        // Navigate to the search route
        navigate(search.route);
      }
    } catch (error) {
      console.error("Error routing Algolia search:", error);
    }
  };

  return (
    <>
      <Card
        withBorder
        radius="md"
        className="sm:w-full mb-4 hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-white hover:border-black duration-900"
        key={index}
      >
        <div
          id="run-search-onclick"
          className="cursor-pointer"
          onClick={runSearchOnClick}
        >
          <div className="flex items-start">
            <div className="max-w-sm">
              <div className="font-semibold pb-1 hover:underline ">
                <Text>{search.name}</Text>
              </div>
              {/* <div className="text-sm pb-4 mr-2">
              <Text>{search.query}</Text>
            </div> */}
              <div className="text-md text-gray-600 font-light pb-2 mr-2">
                <p>Search term: {JSON.parse(search.query).query}</p>
                <div className="flex">
                  <p className="mr-0"></p>
                  <div className="flex">
                    {JSON.parse(search.query).facetFilters &&
                    JSON.parse(search.query).facetFilters.length > 0 ? (
                      JSON.parse(search.query).facetFilters.map(
                        (filter, index) => (
                          <p key={index} className="capitalize mr-2">
                            {filter.map((f, i) => (
                              <span key={i}>
                                {f}
                                {i !== filter.length - 1 ? ", " : ""}
                              </span>
                            ))}
                          </p>
                        )
                      )
                    ) : (
                      <p>None</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-items-end flex-grow">
              <div className="ml-auto">
                <AiOutlineSearch size="1.2rem" />
              </div>
            </div>
          </div>
        </div>
        {/* <Text>{JSON.stringify(search.query)}</Text> */}
      </Card>
    </>
  );
};

export default SavedSearchesCardMinimal;
