import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import ReactGA from "react-ga4"
// Helmet
import { HelmetProvider } from 'react-helmet-async';
// Mantine
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import "primereact/resources/themes/saga-green/theme.css";
import { PrimeReactProvider } from 'primereact/api';


// Google analytics
ReactGA.initialize('G-CD48487V2T');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
    <PrimeReactProvider>
    <NotificationsProvider position='bottom-right' zIndex={1100}>
      <MantineProvider
        theme={{
          fontFamily: "Barlow",
          colors: {
            'bb-blue': ['#d5eeed', '#5FCCDB', '#44CADC', '#2AC9DE', '#1AC2D9', '#11B7CD', '#09ADC3', '#0E99AC', '#128797', '#147885'],
            'bb-light-green': ['#87eeab', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
          },
        }}
        withGlobalStyles withNormalizeCSS>
          <ModalsProvider>
        <App />
        </ModalsProvider>
      </MantineProvider>
    </NotificationsProvider>
    </PrimeReactProvider>
    </HelmetProvider>
  </React.StrictMode>
);